import React, { Component } from 'react'
import { Pressable, Text, Image } from 'react-native'
import { styles } from './styles'
import { IAvatar } from './interface'

interface AvatarProps extends IAvatar {}

interface AvatarState {
    hover: boolean
}

class Avatar extends Component<AvatarProps, AvatarState> {
    constructor(props: AvatarProps) {
        super(props)
        this.state = {
            hover: false,
        }
    }

    renderContent = () => {
        const { image, name, textStyle, size } = this.props

        let font_size = size ? Number(size) / 3 : 0
        if (image) {
            return image
        } else if (name) {
            return (
                <Text
                    style={[styles.text, textStyle, { fontSize: font_size }]}
                    testID={this.props.testIDText}
                >
                    {name.charAt(0)}
                </Text>
            )
        } else {
            return null
        }
    }

    render() {
        const {
            edit,
            onPress,
            onPressEdit,
            size,
            backgroundColor,
            style,
            testIDContainer,
            testIDEdit,
            editIcon,
            editText = 'Edit Picture',
            ...otherPressableProps
        } = this.props
        const { hover } = this.state
        const _backgroundColor = backgroundColor
            ? { backgroundColor: backgroundColor }
            : {}
        const _size = size ? { height: Number(size), width: Number(size) } : {}
        let mainSize: any = size
        let font_size = mainSize / 3

        return (
            <Pressable
                onPress={onPress}
                style={[styles.container, _backgroundColor, style, _size]}
                testID={testIDContainer}
                {...otherPressableProps}
            >
                {this.renderContent()}
                {(edit || hover) && (
                    <Pressable
                        style={[styles.overlay]}
                        testID={testIDEdit}
                        onPress={onPressEdit}
                    >
                        {editIcon || (
                            <Image
                                source={{
                                    uri: 'https://i.imgur.com/aD42TN5.png',
                                }}
                                style={{
                                    height: font_size / 3,
                                    width: font_size / 3,
                                    marginBottom: 4,
                                }}
                            />
                        )}

                        <Text
                            style={{ fontSize: font_size / 3, color: 'white' }}
                        >
                            {editText}
                        </Text>
                    </Pressable>
                )}
            </Pressable>
        )
    }
}

export default Avatar
