//@ts-nocheck
import React, { Component } from 'react'
import ProgressIndicatorCircular from './component/progressIndicatorCircluar'
import ProgressIndicatorBar from './component/progressIndicatorBar'
import {
    ProgressIndicatorBarProps,
    ProgressIndicatorCircularProps,
    ProgressIndicatorProps,
} from './interface'

class ProgressIndicator extends Component<
    ProgressIndicatorProps &
        (ProgressIndicatorCircularProps | ProgressIndicatorBarProps)
> {
    render() {
        return (
            <>
                {this.props.progressIndicatorCircular == true ? (
                    <ProgressIndicatorCircular {...this.props} />
                ) : (
                    <ProgressIndicatorBar {...this.props} />
                )}
            </>
        )
    }
}

export default ProgressIndicator
