//@ts-nocheck
import React, { Component } from 'react'
import { View, Image, Pressable, FlatList, Text } from 'react-native'
import styles from './styles'
import STRINGS from '../../utils/strings'

interface PaginationProps {
    /** Total number of pages */
    pageSize?: number
    /** Active page number */
    pageNumber?: number
    totalItems?: number
    onPageChange?: (page: number) => void
    /** Container  style */
    containerStyle?: object
    subContainerStyle?: object
    pageViewStyle?: object
    pageStyle?: object
    activePageStyle?: object
    ellipseStyle?: object
    iconStyle?: object
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    showIcon?: boolean
    showRightText?: boolean
    totalTextView?: object
    totalItemtext?: object
}

interface PaginationState {
    selectedPage: number | null
    startPage: number
    endPage: number
    showEllipsis: boolean
}

class Pagination extends Component<PaginationProps, PaginationState> {
    constructor(props: PaginationProps) {
        super(props)
        this.state = {
            selectedPage: null,
            startPage: props.pageNumber || 1,
            endPage: props.pageSize || 1,
            showEllipsis: (props.pageSize || 1) > 2,
        }
    }

    componentDidMount() {
        const { pageNumber, pageSize } = this.props
        this.setState({
            selectedPage: pageNumber || 1,
            startPage: Math.max(1, (pageNumber || 1) - 1),
            endPage: Math.min((pageNumber || 1) + 1, pageSize || 1),
            showEllipsis:
                (pageSize || 1) > 2 && (pageNumber || 1) < (pageSize || 1) - 1,
        })
    }

    componentDidUpdate(prevProps: PaginationProps) {
        const { pageNumber, pageSize } = this.props
        if (
            pageNumber !== prevProps.pageNumber ||
            pageSize !== prevProps.pageSize
        ) {
            this.setState({
                selectedPage: pageNumber || 1,
                startPage: Math.max(1, (pageNumber || 1) - 1),
                endPage: Math.min((pageNumber || 1) + 1, pageSize || 1),
                showEllipsis:
                    (pageSize || 1) > 2 &&
                    (pageNumber || 1) < (pageSize || 1) - 1,
            })
        }
    }

    handlePageChange = (page: number) => {
        this.setState({ selectedPage: page })
        const { onPageChange } = this.props
        if (onPageChange) {
            onPageChange(page)
        }
    }

    renderPageButton = ({ item }: { item: any }) => {
        const { selectedPage } = this.state
        const isActive = selectedPage === item
        const isEllipsis = item === '...' // Check if the item is ellipsis

        return (
            <Pressable
                testID={STRINGS.PAGE_BTN_TESTID}
                style={[
                    styles.pageButton,
                    isActive && styles.activePageButton,
                    this.props.pageViewStyle,
                ]}
                onPress={() => this.handlePageChange(item)}
            >
                <Text
                    style={[
                        isActive ? styles.activePageText : styles.pageText,
                        isEllipsis
                            ? [styles.ellipsisText, this.props.ellipseStyle]
                            : null, // ellipsis text style
                        isActive
                            ? this.props.activePageStyle
                            : this.props.pageStyle,
                        isEllipsis ? { textDecorationLine: 'none' } : null, // Remove underline for ellipsis
                    ]}
                >
                    {item}
                </Text>
            </Pressable>
        )
    }
    goToPrevPage = () => {
        const { selectedPage, startPage, endPage } = this.state
        const { pageSize } = this.props
        if (selectedPage && selectedPage > 1) {
            if (selectedPage === startPage && startPage !== 1) {
                this.setState({
                    startPage: startPage - 1,
                    endPage: endPage - 1,
                    showEllipsis: startPage > 2,
                })
            } else {
                this.handlePageChange((selectedPage as number) - 1)
            }
        }
    }

    goToNextPage = () => {
        const { selectedPage, startPage, endPage } = this.state
        const { pageSize } = this.props
        if (selectedPage && selectedPage < (pageSize || 1)) {
            if (selectedPage === endPage && endPage !== (pageSize || 1)) {
                this.setState({
                    startPage: startPage + 1,
                    endPage: endPage + 1,
                    showEllipsis: endPage < (pageSize || 1) - 1,
                })
            } else {
                this.handlePageChange((selectedPage as number) + 1)
            }
        }
    }

    renderEllipsisButton = () => {
        return (
            <View>
                <Text style={[styles.ellipsisText, this.props.ellipseStyle]}>
                    ...
                </Text>
            </View>
        )
    }

    getPageNumbers = () => {
        const { pageSize } = this.props
        const { startPage, endPage, showEllipsis } = this.state
        const pageNumbers: any = []

        if (pageSize && pageSize <= 4) {
            pageNumbers.push(
                ...Array.from({ length: pageSize }, (_, index) => index + 1),
            )
        } else if (showEllipsis) {
            pageNumbers.push(startPage)
            if (startPage && startPage < endPage - 1) {
                pageNumbers.push(endPage - 1)
            }
            pageNumbers.push(endPage)
            if (pageSize && pageSize > 2) {
                pageNumbers.push('...')
            }
            if (endPage && endPage < (pageSize || 1)) {
                pageNumbers.push(pageSize)
            }
        } else {
            pageNumbers.push(
                ...Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => startPage + index,
                ),
            )
        }

        return pageNumbers
    }

    getPageRangeText = (): string => {
        const { pageNumber, pageSize, totalItems } = this.props
        if (pageSize && pageSize > 0) {
            return `${pageNumber} - ${pageSize} of ${totalItems} results`
        }
        return ''
    }

    render() {
        const {
            containerStyle,
            subContainerStyle,
            iconLeft,
            iconRight,
            iconStyle,
            showIcon,
            showRightText,
            totalTextView,
            totalItemtext,
        } = this.props
        const pageNumbers = this.getPageNumbers()

        return (
            <View style={[styles.container, containerStyle]}>
                <View style={[styles.subContainer, subContainerStyle]}>
                    {showIcon && (
                        <Pressable
                            testID={STRINGS.LEFT_BTN_TESTID}
                            style={styles.leftIconContainer}
                            onPress={this.goToPrevPage}
                            disabled={this.state.selectedPage === 1}
                        >
                            {iconLeft ? (
                                iconLeft
                            ) : (
                                <Image
                                    resizeMode={'contain'}
                                    style={[styles.icon]}
                                    source={{
                                        uri: 'https://i.imgur.com/wS6e62V.png',
                                    }}
                                />
                            )}
                        </Pressable>
                    )}
                    <View>
                        <FlatList
                            data={pageNumbers}
                            renderItem={this.renderPageButton}
                            keyExtractor={(item) => item.toString()}
                            contentContainerStyle={styles.itemFlatlistContainer}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    {showIcon && (
                        <Pressable
                            testID={STRINGS.RIGHT_BTN_TESTID}
                            onPress={this.goToNextPage}
                            disabled={
                                this.state.selectedPage ===
                                (this.props.pageSize || 1)
                            }
                        >
                            {iconRight ? (
                                iconRight
                            ) : (
                                <Image
                                    resizeMode={'contain'}
                                    style={[styles.icon, iconStyle]}
                                    source={{
                                        uri: 'https://i.imgur.com/cK6JBiC.png',
                                    }}
                                />
                            )}
                        </Pressable>
                    )}
                </View>
                {showRightText && (
                    <View style={[styles.totalTextView, totalTextView]}>
                        <Text style={[styles.totalItemtext, totalItemtext]}>
                            {this.getPageRangeText()}
                        </Text>
                    </View>
                )}
            </View>
        )
    }
}

export default Pagination
