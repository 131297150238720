import React, { useMemo, useState } from 'react'
import { ViewStyle, Pressable, PressableProps, StyleProp } from 'react-native'
import style from './style'
import { Image, View, Text } from 'react-native'
import { Input, InputProps } from '../../Input/Input'
import { usePlatfomHook } from '../../../utils/getPlatformType'
import SideNavigation, {
    SideNavigationProps,
    SideNavigationItem,
} from '../SideNavigation/sideNavigation'
import hamburgerImage from '../../../assets/images/hamburger.png'

export interface TopNavigationBarProps {
    testID?: string
    containerStyle?: StyleProp<ViewStyle>
    headerLogoImage?: React.ReactNode
    hamburgerIcon?: React.ReactNode
    hamburgerButton?: HamburgerButtonProps
    displayInputBox?: boolean
    inputBox?: InputProps
    navigationItems?: SideNavigationProps
    rightContainer?: Array<RightContainer>
}

export interface RightContainer {
    desktop?: React.ReactNode
    mobile?: React.ReactNode
    tablet?: React.ReactNode
}

interface HamburgerButtonProps extends PressableProps {
    onPress?: () => void
}

const NavBar = (props: TopNavigationBarProps) => {
    const {
        testID,
        containerStyle,
        headerLogoImage,
        hamburgerIcon,
        hamburgerButton,
        displayInputBox = true,
        inputBox,
        navigationItems,
        rightContainer,
    } = props

    const [topNavContainerHeight, setTopNavContainerHeight] = useState(0)
    const [showDrawer, setShowDrawer] = useState(false)
    const { screenType, isDesktop, isMobile, isTablet } = usePlatfomHook()

    const renderButtonName = (sideNavItem: SideNavigationItem) => {
        if (!isDesktop) {
            return null
        }
        return (
            <View key={sideNavItem.id}>
                <Pressable
                    testID={`navigation-button-${testID}-${sideNavItem.id}`}
                    style={[
                        style.navButtonNameStyle,
                        sideNavItem.containerStyle,
                    ]}
                    {...navigationItems}
                    onPress={() => {
                        delete sideNavItem.items
                        navigationItems?.onPress(sideNavItem, undefined)
                    }}
                >
                    <Text
                        style={[
                            style.navButtonNameStyle,
                            sideNavItem?.textStyle,
                        ]}
                    >
                        {sideNavItem.text}
                    </Text>
                </Pressable>
            </View>
        )
    }

    const handleTopNavLayout = (event) => {
        setTopNavContainerHeight(event.nativeEvent.layout?.height)
    }

    const adjustDrawer = () => {
        setShowDrawer(!showDrawer)
        hamburgerButton?.onPress?.()
    }

    const displayHamburgerMenu = useMemo(() => {
        if (isDesktop) {
            return null
        }
        return (
            <Pressable
                {...hamburgerButton}
                onPress={() => adjustDrawer()}
                testID={`hamburger-button-${testID}`}
            >
                {hamburgerIcon ? (
                    <>{hamburgerIcon}</>
                ) : (
                    <Image
                        source={hamburgerImage}
                        style={style.hamburgerStyle}
                    />
                )}
            </Pressable>
        )
    }, [screenType, showDrawer])

    const getRightContainerItems = useMemo(() => {
        return rightContainer?.map((item, index) => {
            let component = item.desktop || item.mobile || item.tablet
            if (isMobile) {
                component = item.mobile
            } else if (isTablet) {
                component = item.tablet
            } else if (isDesktop) {
                component = item.desktop
            }
            return (
                <View
                    key={`right-container-${index}`}
                    style={style.rightIconStyle}
                >
                    {component}
                </View>
            )
        })
    }, [rightContainer, screenType])

    return (
        <>
            <View
                style={[
                    style.container,
                    isDesktop && style.desktopPadding,
                    containerStyle,
                ]}
                onLayout={handleTopNavLayout}
            >
                <View style={[style.flexStyle]}>
                    {displayHamburgerMenu}
                    <View>{headerLogoImage}</View>
                    {isDesktop && (
                        <>{navigationItems?.items?.map(renderButtonName)}</>
                    )}
                </View>
                <View style={[style.flexStyle, style.rightIcons]}>
                    {getRightContainerItems}
                </View>
            </View>
            <SideNavigation
                testID={testID}
                showResponsiveButton={false}
                showResponsiveDrawerOverride={true}
                showResponsiveDrawer={showDrawer}
                containerStyle={{ top: topNavContainerHeight }}
                {...navigationItems}
            />
        </>
    )
}

export default NavBar
