import React, { Component } from 'react'
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    TouchableWithoutFeedback,
} from 'react-native'
import { IDropDown, Item, IDropDownState } from './interface'
import { styles } from './styles'

export class DropDown extends Component<IDropDown, IDropDownState> {
    constructor(props: IDropDown) {
        super(props)
        this.state = {
            isVisible: props.initialVisibility || false,
            value: null,
        }
    }

    onPressPlaceHolder = () => {
        this.setState({ isVisible: !this.state.isVisible })
    }

    onValuePressIn = (item: Item) => {
        this.setState({ value: item })
    }

    onValuePressOut = (item: Item) => {
        this.setState({ isVisible: false })
        this.props?.onSelect && this.props?.onSelect(item)
    }

    RenderAccordionHeader = () => {
        const {
            placeHolder,
            dropdownIcon,
            placeHolderContainerStyle,
            headerTextStyle,
        } = this.props
        const { value } = this.state
        return (
            <TouchableOpacity
                onPress={this.onPressPlaceHolder}
                style={[styles.placeHolderContainer, placeHolderContainerStyle]}
            >
                {value?.image && (
                    <View style={styles.iconContainer}>{value.image}</View>
                )}
                <Text style={[styles.placeholderTextStyle, headerTextStyle]}>
                    {value?.name ? value?.name : placeHolder}
                </Text>
                <View style={styles.imageContainer}>
                    {dropdownIcon ? (
                        dropdownIcon
                    ) : (
                        <Image
                            resizeMode="contain"
                            style={[styles.image]}
                            source={{ uri: 'https://i.imgur.com/aXzX4Z7.png' }}
                        />
                    )}
                </View>
            </TouchableOpacity>
        )
    }

    RenderAccordionContent = ({ item, index }: any) => {
        const { listContainerStyle, textStyle, selectedListContainerStyle } =
            this.props
        const { value } = this.state
        const isActive = item?.key == value?.key
        return (
            <TouchableOpacity
                activeOpacity={1}
                delayPressOut={80}
                onPressIn={() => this.onValuePressIn(item)}
                onPressOut={() => this.onValuePressOut(item)}
                style={
                    isActive
                        ? [
                              styles.selectedListContainer,
                              selectedListContainerStyle,
                          ]
                        : [styles.listContainer, listContainerStyle]
                }
                key={index}
            >
                {item.image ? (
                    <View style={styles.iconContainer}>{item.image}</View>
                ) : null}
                <Text style={[styles.placeholderTextStyle, textStyle]}>
                    {item?.name}
                </Text>
            </TouchableOpacity>
        )
    }

    render() {
        const { options, containerStyle, contentContainerStyle, zIndex } =
            this.props
        const { isVisible } = this.state
        return (
            <View
                style={[
                    styles.container,
                    zIndex ? { zIndex } : {},
                    containerStyle,
                ]}
            >
                {this.RenderAccordionHeader()}
                {options.length > 0 && isVisible ? (
                    <View
                        style={[styles.contentContainer, contentContainerStyle]}
                    >
                        {options.map((item: any, index: any) => {
                            return this.RenderAccordionContent({ item, index })
                        })}
                    </View>
                ) : null}
            </View>
        )
    }
}

export default DropDown
