//@ts-nocheck
import React, { Component } from 'react'
import {
    View,
    Pressable,
    Text,
    Image,
    ViewStyle,
    TextStyle,
    StyleProp,
    PressableProps,
} from 'react-native'
import styles from './styles'
import COLORS from '../../utils/colors'
import STRINGS from '../../utils/strings'

interface BreadcrumbItem {
    id: string
    name: string
}

export interface PressableItemsProps extends PressableProps {
    onPress: (index: number) => any
}

interface BreadcrumbsProps {
    /** Data for Breadcrumbs */
    pageData: BreadcrumbItem[]
    icon?: React.ReactNode
    textStyle?: StyleProp<TextStyle>
    activeTextStyle?: StyleProp<TextStyle>
    hoverViewStyle?: StyleProp<ViewStyle>
    /** Container content pass to the FlatList */
    containerStyle?: StyleProp<ViewStyle>
    activePageIndex?: number
    /**  onPress calback is expected to recieve index prop*/
    pressableProps?: PressableItemsProps
}

interface BreadcrumbsState {
    selectedItem: number | null
    hoveredIndex: number | null
}

class Breadcrumbs extends Component<BreadcrumbsProps, BreadcrumbsState> {
    constructor(props: BreadcrumbsProps) {
        super(props)
        this.state = {
            selectedItem: props.activePageIndex || null,
            hoveredIndex: null,
        }
    }

    onIndexChangeVal = (index: number): void => {
        this.setState({ selectedItem: index })
        this.props?.pressableProps?.onPress(index)
    }

    onIndexHoverVal = (index: number | null): void => {
        this.setState({ hoveredIndex: index })
    }

    renderItem = ({
        item,
        index,
    }: {
        item: BreadcrumbItem
        index: number
    }): React.ReactElement => {
        const { selectedItem, hoveredIndex } = this.state
        const { icon, activeTextStyle, textStyle, hoverViewStyle } = this.props
        const isSelected = selectedItem === index
        const isHovered = hoveredIndex === index

        return (
            <View style={styles.item} key={index}>
                {index > 0 &&
                    (icon ? (
                        icon
                    ) : (
                        <Image
                            resizeMode="contain"
                            style={[styles.icon]}
                            source={{
                                uri: 'https://i.imgur.com/JuKMaKC.png',
                            }}
                        />
                    ))}
                <Pressable
                    testID={STRINGS.BTN_TESTID}
                    {...this.props?.pressableProps}
                    onPress={() => this.onIndexChangeVal(index)}
                    onHoverIn={() => this.onIndexHoverVal(index)}
                    onHoverOut={() => this.onIndexHoverVal(null)}
                >
                    <View
                        style={[
                            styles.hoverView,
                            {
                                backgroundColor: isHovered
                                    ? COLORS.COLOR_BTNGRAY
                                    : 'transparent',
                            },
                            isHovered ? hoverViewStyle : {},
                        ]}
                    >
                        <Text
                            style={[
                                isSelected
                                    ? styles.boldText
                                    : styles.breadcrumbText,
                                isSelected ? activeTextStyle : textStyle,
                            ]}
                        >
                            {item.name}
                        </Text>
                    </View>
                </Pressable>
            </View>
        )
    }

    render() {
        const { pageData, containerStyle } = this.props

        return (
            <View style={[styles.contentContainer, containerStyle]}>
                {pageData.map((item, index) => {
                    return this.renderItem({ item, index })
                })}
            </View>
        )
    }
}

export default Breadcrumbs
